import React, {useState} from 'react'
import {graphql, StaticQuery} from 'gatsby'
import {navigate} from 'gatsby-link'
import Recaptcha from 'react-recaptcha'
import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'

import BlockContent from '../components/portableText'

import styles from '../components/page.module.css'

const Page = () => {
  function encode (data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  // State
  const [formValues, setFormValues] = useState({services: [], locations: []})
  const [isVerified, setIsVerified] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  // Google Recaptcha
  let recaptchaLoaded = function () {
    console.log('Loaded')
  }
  let expiredCallback = function () {
    console.log('expired')
    setIsVerified(false)
    setRecaptchaResponse(null)
  }
  let verifyCallback = function (response) {
    console.log(response)
    if (response) {
      setIsVerified(true)
      setRecaptchaResponse(response)
    } else {
      setIsVerified(false)
      setRecaptchaResponse(null)
    }
  }

  // const handleChange = e => {
  //   setFormValues({...formValues, [e.target.name]: e.target.value})
  // }
  // const handleDateChange = (date) => {
  //   // .toLocaleString()
  //   setFormValues({...formValues, dateTime: date, date: date.toLocaleString()})
  // }
  const handleChange = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }
  const handleSelectChange = locations => {
    // Test if the value exists
    if (locations) {
      locations.map(val => {
        setFormValues({...formValues, locations: [...formValues.locations, val.value]})
      })
    }
  }
  const handleSelectChange2 = services => {
    // Test if the value exists
    if (services) {
      services.map(val => {
        setFormValues({...formValues, services: [...formValues.services, val.value]})
      })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    // console.log({e})

    if (isVerified && formValues.firstName && formValues.lastName && formValues.email && formValues.message) {
      console.log('valid form')
      // console.log({formValues})
      setFormErrors(false)

      const form = e.target
      fetch('/.netlify/functions/model', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: encode({
          // 'to': 'wax@screaminpeach.com',
          'to': 'wax@screaminpeach.com',
          'from': 'wax@screaminpeach.com',
          'subject': 'ScreaminPeach.com - Model',
          ...formValues,
          recaptcha: recaptchaResponse
        })
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => console.error(error))
    } else {
      console.log('invalid form')
      setFormErrors(true)
    }
  }

  return (
    <StaticQuery
      query={graphql`
            query sanityModelPage {
              sanityModel {
                title
                subTitle
                _rawBody
                mainImage {
                  asset {
                    fluid(maxWidth: 1900) {
                      ...GatsbySanityImageFluid
                    }
                    fixed(width: 1900) {
                      ...GatsbySanityImageFixed
                    }
                  }
                }
                formSettings {
                  thankyou
                  enableForm
                }
                serviceOptions {
                  _key
                  label
                  value
                }
                locationsOptions {
                  _key
                  label
                  value
                }
                seoSettings {
                  title
                  description
                }
              }
            }
          `}
      render={data => (
        <>
          {data.sanityModel.seoSettings && data.sanityModel.seoSettings.title && data.sanityModel.seoSettings.description && (<SEO title={data.sanityModel.seoSettings.title} description={data.sanityModel.seoSettings.description} />)}

          <Layout mainImage={data.sanityModel.mainImage} title={data.sanityModel.title}>
            <article className={styles.root} >

              <Container>
                <div className={styles.pageTitleWrapper}>
                  <h1 className={styles.pageTitle}>{data.sanityModel.title}</h1>
                  <h4 className={styles.pagesubTitle}>{data.sanityModel.subTitle}</h4>
                </div>
                <section className='section'>

                  <div className='container'>
                    <div className='content'>
                      <div className={styles.mainContent}>
                        {data.sanityModel._rawBody && <BlockContent blocks={data.sanityModel._rawBody} />}
                      </div>
                      {data.sanityModel.formSettings.enableForm && (
                        <form
                          name='ScreaminPeach.com Model'
                          method='post'
                          action={data.sanityModel.formSettings.thankyou}
                          onSubmit={handleSubmit}
                        >

                          <input type='hidden' name='form-name' value='contact' />
                          <div hidden>
                            <label>
                              Don’t fill this out:{' '}
                              <input name='bot-field' onChange={handleChange} />
                            </label>
                          </div>

                          {/*   <div className='field'>
                            <label className={styles.customLabel} htmlFor={'date'}>When Can You Start?</label>
                            <DatePicker
                              className={styles.customInput}
                              showTimeSelect
                              selected={formValues.dateTime}
                              onChange={handleDateChange}
                              onSelect={handleDateChange}
                              dateFormat='MMMM d, yyyy h:mm aa'
                              name='date'
                              required
                            />
                          </div>
                      */}

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'firstName'}>
                              First Name
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'firstName'}
                                onChange={handleChange}
                                id={'firstName'}
                                required
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'lastName'}>
                              Last Name
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'lastName'}
                                onChange={handleChange}
                                id={'lastName'}
                                required
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'phone'}>
                              Phone Number
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'phone'}
                                onChange={handleChange}
                                id={'phone'}
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'email'}>
                              Email
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'email'}
                                onChange={handleChange}
                                id={'email'}
                                required
                              />
                            </div>
                          </div>

                          {/* <div className='field'>
                            <label className={styles.customLabel} htmlFor={'location'}>
                              Screamin Location (select all that apply)
                            </label>
                            <div className='control'>
                              <select className={styles.customSelect}
                                name={'location'}
                                onChange={handleChange}
                                id={'location'} required>
                                <option value='' defaultValue>Choose a Location</option>
                                <option value='Old Town'>Old Town</option>
                                <option value='South Fort Collins'>South Fort Collins</option>
                                <option value='Longmont'>Longmont</option>
                                <option value='Johnstown'>Johnstown</option>
                              </select>
                            </div>
                    </div> */}

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'locations'}>
                              Screamin Location (select all that apply)
                            </label>
                            <Select
                              options={data.sanityModel.locationsOptions}
                              isMulti
                              onChange={handleSelectChange}
                              name='locations'
                              onBlur={event => event.preventDefault()}
                            />
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'services'}>
                              What services are you interested in modeling for? (select all that apply)
                            </label>
                            <Select
                              options={data.sanityModel.serviceOptions}
                              isMulti
                              onChange={handleSelectChange2}
                              name='services'
                              onBlur={event => event.preventDefault()}
                            />

                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'message'}>
                              Message
                            </label>
                            <div className='control'>
                              <textarea
                                className={styles.customTextarea}
                                name={'message'}
                                onChange={handleChange}
                                id={'message'}
                                required
                                rows='4'
                              />
                            </div>
                          </div>

                          <div>
                            <br />

                            <Recaptcha
                              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_PUBLIC}
                              render='explicit'
                              verifyCallback={verifyCallback}
                              onloadCallback={recaptchaLoaded}
                              expiredCallback={expiredCallback}
                            />
                          </div>

                          <div className='field'>
                            <button className={styles.customButton} type='submit'>
                              Send
                            </button>
                          </div>

                          {formErrors && (
                            <div className={styles.errors}><p>Unable to submit form. Please make sure all of your fields are filled out.</p></div>
                          )}

                        </form>
                      )}
                    </div>
                  </div>
                </section>
              </Container>
            </article>
          </Layout>
        </>
      )}
    />
  )
}
export default Page
